<template>
  <main id="main" class="main-container">



<!-- Intro stuk van offerte  ####===> VUE werkt-->
    <div class="container-fluid " style=" margin-top:25px;">
        <div class="row">
            <div class="col-12 pl-0 pr-0" style=" margin-top:40px;  margin-bottom:40px;">
                <div class="container">
                    <div class="row">
                        
                        <div class="card d-flex">
                            <h5 class="card-header bg-success font-weight-bold">Online offerte aanvragen.</h5>
                            <div class="card-body">
                              <h4 class="card-title font-weight-bold">Bij mega-schutting.nl kunt u online een offerte aanvragen via onze eigengmaakte tool of via een simpele vormulier.
                                </h4>

                              <p class="card-text">
                                Doorloop de stappen in onderstaande tool. Heb je al jouw wensen ingevoerd? Dan zie je direct wat de totaalprijs is. Zo kom je niet voor verrassingen te staan. Ben je tevreden met de door jou gekozen schutting? Voltooi dan de bestelling. Vervolgens wordt jouw bestelling gecontroleerd en nemen wij bij eventuele vragen of opmerkingen direct contact met je op.
                                Vraag voor specifieke wensen, zoals een gaaspaneel of een schutting met lage schermen, vrijblijvend een offerte bij ons aan. Onze experts denken graag met je mee over de mogelijkheden.
                                Uiteraard zijn alle producten zoals tuinschermen, afdekkappen, betonpalen en platen en glaspanelen ook los te bestellen. Ons gehele assortiment vind je bij producten.
                              </p>


                              <div class="container">
                                <div class="row d-flex justify-content-center">
                                    <div class="col-md-3 mt-4">
                                        <div class="card card-product" v-scroll-to="'#aantalHoeken'" v-on:click="setKeuze('typeAanvraag', 'snel_offerte')" v-bind:class="{'selected': (keuzes.typeAanvraag == 'snel_offerte')}">
                                            <div class="image-container">
                                                <div class="first">
                                                    <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-question"></i></span> </div>
                                                </div> <img src="images/logo_mega-schutting.png" class="img-fluid rounded thumbnail-image">
                                            </div>
                                            <div class="product-detail-container p-2">
                                                <div class="text-center">
                                                    <div class="text-dark prodcattitle">Snelle offerte</div>
                                                </div>
                                             
                                            </div>
                                        </div>
                                    </div>
    
                                    <div class="col-md-3 mt-4">
                                        <div class="card card-product" v-on:click="setKeuze('typeAanvraag', 'ontwerptool')" v-bind:class="{'selected': (keuzes.typeAanvraag == 'ontwerptool')}">
                                            <div class="image-container">
                                                <div class="first">
                                                    <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-question"></i></span> </div>
                                                </div> <img src="images/logo_mega-schutting.png" class="img-fluid rounded thumbnail-image">
                                            </div>
                                            <div class="product-detail-container p-2">
                                                <div class="text-center">
                                                    <div class="text-dark prodcattitle ">Ontwerpt tool gebruiken</div>
                                                </div>
                                             
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                              </div>
                              
                            </div>
                          </div>
                                                                                                       
                    </div>
                </div>
            </div>
        </div>
    </div>


<div v-if="(keuzes.typeAanvraag == 'snel_offerte')">
<!-- Aantal hoeken kiezen ####===> VUE werkt-->
    <div class="container-fluid  bg-gray" id="aantalHoeken">
        <div class="row">
            <div class="col-12 pl-0 pr-0" style=" margin-top:40px;  margin-bottom:40px;">
                <div class="container">
                    <h1 class="mb-2 font-weight-bold">Stap 1: </h1><h3 class="mb-4">Kies het aantal hoeken dat u wilt.</h3>
                    <div class="row">

                        <div class="col-md-3 mt-4">
                            <div class="card card-product"  v-on:click="setKeuze('aantalZijdes', '1')" v-bind:class="{'selected': (keuzes.aantalZijdes == '1')}">
                                <div class="image-container">
                                    <div class="first">
                                        <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info none-op"><i class="fas fa-question"></i></span> </div>
                                    </div> <img src="images/hoek_0.jpg" class="img-fluid rounded thumbnail-image">
                                </div>
                                <div class="product-detail-container p-2">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class=" prodcattitle">Aantal zijde: 1</div>
                                        <div class="d-flex flex-column mb-2"> <span class="new-price"></span> <small class="old-price text-right none">$53.99</small> </div>
                                    </div>
                                 
                                </div>
                            </div>
                        </div>

                        

                        <div class="col-md-3 mt-4">
                            <div class="card card-product"  v-on:click="setKeuze('aantalZijdes', '2')" v-bind:class="{'selected': (keuzes.aantalZijdes == '2')}">
                                <div class="image-container">
                                    <div class="first">
                                        <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info none-op"><i class="fas fa-question"></i></span> </div>
                                    </div> <img src="images/hoek_1.jpg" class="img-fluid rounded thumbnail-image">
                                </div>
                                <div class="product-detail-container p-2">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="prodcattitle">Aantaal zijde: 2</div>
                                        <div class="d-flex flex-column mb-2"> <span class="new-price"></span> <small class="old-price text-right none">$53.99</small> </div>
                                    </div>
                                 
                                </div>
                            </div>
                        </div>

                        <div class="col-md-3 mt-4">
                            <div class="card card-product"  v-on:click="setKeuze('aantalZijdes', '3')" v-bind:class="{'selected': (keuzes.aantalZijdes == '3')}">
                                <div class="image-container">
                                    <div class="first">
                                        <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info none-op"><i class="fas fa-question"></i></span> </div>
                                    </div> <img src="images/hoek_2.jpg" class="img-fluid rounded thumbnail-image">
                                </div>
                                <div class="product-detail-container p-2">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class=" prodcattitle">Aantal zijde: 3</div>
                                        <div class="d-flex flex-column mb-2"> <span class="new-price"></span> <small class="old-price text-right none">$53.99</small> </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-3 mt-4">
                            <div class="card card-product"  v-on:click="setKeuze('aantalZijdes', '4')" v-bind:class="{'selected': (keuzes.aantalZijdes == '4')}">
                                <div class="image-container">
                                    <div class="first">
                                        <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info none-op"><i class="fas fa-question"></i></span> </div>
                                    </div> <img src="images/hoek_3.jpg" class="img-fluid rounded thumbnail-image">
                                </div>
                                <div class="product-detail-container p-2">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="text-dark prodcattitle">Aantal zijde: 4</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-sm-6 col-md-12 mb-2 mb-md-0 mb-4 mb-md-0 bg-light mt-4" v-if="keuzes.aantalZijdes != ''">	
                            <div class="text-center mt-2 font-weight-bold">Vul de lengtes in in meters</div>

                            <form class="container">
                                <div class="row">
                                    <div class="form-group col-md-6"  v-if="keuzes.aantalZijdes >= 1">
                                        <label for="validateA">Zijde: A</label>
                                        <input type="number"  class="form-control" v-bind:class="{ 'border-danger': alertLengteA }" id="validateA" placeholder="5 meter"  required v-model="keuzes.lengtes.zijde_a">
                                    </div>
        
                                    <div class="form-group col-md-6"  v-if="keuzes.aantalZijdes >= 2">
                                        <label for="#">Zijde: B</label>
                                        <input type="number" class="form-control" v-bind:class="{ 'border-danger': alertLengteB }"   placeholder="5 meter" v-model="keuzes.lengtes.zijde_b">       
                                     </div>
                                    <div class="form-group col-md-6"  v-if="keuzes.aantalZijdes >= 3">
                                        <label for="#">Zijde: C</label>
                                        <input type="number" class="form-control" v-bind:class="{ 'border-danger': alertLengteC }"  placeholder="5 meter" v-model="keuzes.lengtes.zijde_c">       
                                     </div>
                                    <div class="form-group col-md-6"  v-if="keuzes.aantalZijdes >= 4">
                                        <label for="#">Zijde: B</label>
                                        <input type="number" class="form-control" v-bind:class="{ 'border-danger': alertLengteD }"  placeholder="5 meter" v-model="keuzes.lengtes.zijde_d">       
                                     </div>
                                </div>
                                 <p class="row justify-content-center"><button type="submit" v-on:click="naarPalen()" class="btn btn-success btn-block col-md-6 mt-4" v-scroll-to="{el: cmScrollInput}" ><i class="fas fa-arrow-down"></i> Volgende stap </button></p>

                            </form>
                            
                        </div>
                                                                                                       
                    </div>
                </div>
            </div>
        </div>
    </div>
        
    <div v-if="(keuzes.lengtes.zijde_a != '0')">
        <!-- Paal soort kiezen  ####===> VUE werkt-->
            <div class="container-fluid bg-gray container-section" id="paal">
                <div class="row">
                    <div class="col-12 pl-0 pr-0" style=" margin-top:10px;  margin-bottom:40px;">

                        <div class="container mt-5 mb-5">
                            <h1 class="mb-2 font-weight-bold">Stap 2: </h1><h3 class="mb-4">Kies uw gewenste soort paal.</h3>
                            <div class="row d-flex justify-content-center">
                                
                                <div class="col-md-3 mt-4">
                                    <div class="card card-product" v-scroll-to="'#scherm'" v-on:click="setKeuze('soortPaal', 'beton')" v-bind:class="{'selected': (keuzes.soortPaal == 'beton')}">
                                        <div class="image-container shadow">
                                            <div class="first">
                                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-question"></i></span> </div>
                                            </div> <img src="images/beton.png" class="img-fluid rounded thumbnail-image">
                                        </div>
                                        <div class="product-detail-container p-2">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="text-dark prodcattitle">Betonen paal</div>
                                                <div class="d-flex flex-column mb-2"> <span class="new-price">€ 32,99</span> <small class="old-price text-right none">$53.99</small> </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>
                    
                                <div class="col-md-3 mt-4">
                                    <div class="card card-product" v-scroll-to="'#scherm'" v-on:click="setKeuze('soortPaal', 'hout')" v-bind:class="{'selected': (keuzes.soortPaal == 'hout')}">
                                        <div class="image-container shadow">
                                            <div class="first">
                                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-question"></i></span> </div>
                                            </div> <img src="images/hout.png" class="img-fluid rounded thumbnail-image">
                                        </div>
                                        <div class="product-detail-container p-2">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="text-dark prodcattitle">Houten paal</div>
                                                <div class="d-flex flex-column mb-2"> <span class="new-price">€ 32,99</span> <small class="old-price text-right none">$53.99</small> </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>  
                                
                            </div>
                        </div>  
                    </div>
                </div>
            </div>


        <!-- scherm kiezen -->
            <div class="container-fluid bg-gray container-section" id="scherm" v-if="schermKiezen">
                <div class="row">
                    <div class="col-12 pl-0 pr-0" style=" margin-top:10px;  margin-bottom:40px;">

                        <div class="container mt-5 mb-5">
                            <h1 class="mb-2 font-weight-bold">Stap 3: </h1><h3 class="mb-4">Kies het gewenste soort scherm.</h3>
                            <div class="row d-flex justify-content-center">
                                
                                <div class="col-md-3 mt-4">
                                    <div class="card card-product" v-scroll-to="{el: cmScrollScherm}" v-on:click="setKeuze('soortScherm', 'verticaal')" v-bind:class="{'selected': (keuzes.soortScherm == 'verticaal')}">
                                        <div class="image-container shadow">
                                            <div class="first">
                                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-question"></i></span> </div>
                                            </div> <img src="images/verticaal.png" class="img-fluid rounded thumbnail-image">
                                        </div>
                                        <div class="product-detail-container p-2">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="text-dark prodcattitle">Verticaal scherm</div>
                                                <div class="d-flex flex-column mb-2"> <span class="new-price">€ 32,99</span> <small class="old-price text-right none">$53.99</small> </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>
                    
                                <div class="col-md-3 mt-4">
                                    <div class="card card-product" v-scroll-to="{el: cmScrollScherm}" v-on:click="setKeuze('soortScherm', 'horizontaal')" v-bind:class="{'selected': (keuzes.soortScherm == 'horizontaal')}">
                                        <div class="image-container shadow">
                                            <div class="first">
                                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-question"></i></span> </div>
                                            </div> <img src="images/horizontaal.png" class="img-fluid rounded thumbnail-image">
                                        </div>
                                        <div class="product-detail-container p-2">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="text-dark prodcattitle">Horizontaal scherm</div>
                                                <div class="d-flex flex-column mb-2"> <span class="new-price">€ 32,99</span> <small class="old-price text-right none">$53.99</small> </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>  
                    
                                
                                    <div class="col-md-3 mt-4"  v-if="(keuzes.soortPaal != 'hout')">
                                        <div class="card card-product" v-scroll-to="'#soortHout'" v-on:click="setKeuze('soortScherm', 'dicht_blokhut')" v-bind:class="{'selected': (keuzes.soortScherm == 'dicht_blokhut')}">
                                            <div class="image-container shadow">
                                                <div class="first">
                                                    <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-question"></i></span> </div>
                                                </div> <img src="images/blokhut.png" class="img-fluid rounded thumbnail-image">
                                            </div>
                                            <div class="product-detail-container p-2">
                                                <div class="d-flex justify-content-between align-items-center">
                                                    <div class="text-dark prodcattitle">Dicht/Blokhut</div>
                                                    <div class="d-flex flex-column mb-2"> <span class="new-price">€ 32,99</span> <small class="old-price text-right none">$53.99</small> </div>
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div>  

                                    <div class="col-md-3 mt-4" v-if="(keuzes.soortPaal != 'hout')">
                                        <div class="card card-product" v-scroll-to="'#motief'" v-on:click="setKeuze('soortScherm', 'volledig_beton')" v-bind:class="{'selected': (keuzes.soortScherm == 'volledig_beton')}">
                                            <div class="image-container shadow">
                                                <div class="first">
                                                    <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-question"></i></span> </div>
                                                </div> <img src="images/muur.png" class="img-fluid rounded thumbnail-image">
                                            </div>
                                            <div class="product-detail-container p-2">
                                                <div class="d-flex justify-content-between align-items-center">
                                                    <div class="text-dark prodcattitle">Volledig beton</div>
                                                    <div class="d-flex flex-column mb-2"> <span class="new-price">€ 32,99</span> <small class="old-price text-right none">$53.99</small> </div>
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div>  
                                
                            </div>
                        </div>  
                    </div>
                </div>
            </div> 

            
        <!-- schutting typen kiezen -->
            <div class="container-fluid bg-gray container-section" id="schutting" v-if="typeSchutting" >
                <div class="row">
                    <div class="col-12 pl-0 pr-0" style=" margin-top:10px;  margin-bottom:40px;">

                        <div class="container mt-5 mb-5">
                            <h1 class="mb-2 font-weight-bold">Type schutting </h1><h3 class="mb-4">Kies het gewenste type schutting.</h3>
                            <div class="row">
                                
                                <div class="col-md-3 mt-4">
                                    <div class="card card-product" v-scroll-to="'#soortHout'" v-on:click="setKeuze('typeSchutting', 'standaart')" v-bind:class="{'selected': (keuzes.typeSchutting == 'standaart')}">
                                        <div class="image-container shadow">
                                            <div class="first">
                                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-question"></i></span> </div>
                                            </div> <img src="https://cdn.mega-schutting.nl/images/i255_250_4/84f81e84cc3856f7e6e105b770ddc5f7/media/algemeen/showroom/0413103D-SCABLOK.PNG" class="img-fluid rounded thumbnail-image">
                                        </div>
                                        <div class="product-detail-container p-2">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="text-dark prodcattitle">Standaart</div>
                                                <div class="d-flex flex-column mb-2"> <span class="new-price">€ 32,99</span> <small class="old-price text-right none">$53.99</small> </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>
                    
                                <div class="col-md-3 mt-4">
                                    <div class="card card-product" v-scroll-to="'#soortHout'" v-on:click="setKeuze('typeSchutting', 'semi_luxe')" v-bind:class="{'selected': (keuzes.typeSchutting == 'semi_luxe')}">
                                        <div class="image-container shadow">
                                            <div class="first">
                                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-question"></i></span> </div>
                                            </div> <img src="https://cdn.mega-schutting.nl/images/i255_250_4/84f81e84cc3856f7e6e105b770ddc5f7/media/algemeen/showroom/0413103D-SCABLOK.PNG" class="img-fluid rounded thumbnail-image">
                                        </div>
                                        <div class="product-detail-container p-2">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="text-dark prodcattitle">Semi-luxe</div>
                                                <div class="d-flex flex-column mb-2"> <span class="new-price">€ 43,99</span> <small class="old-price text-right none">$53.99</small> </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>
                    
                                <div class="col-md-3 mt-4">
                                    <div class="card card-product" v-scroll-to="'#soortHout'" v-on:click="setKeuze('typeSchutting', 'luxe')" v-bind:class="{'selected': (keuzes.typeSchutting == 'luxe')}">
                                        <div class="image-container shadow">
                                            <div class="first">
                                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-question"></i></span> </div>
                                            </div> <img src="https://cdn.mega-schutting.nl/images/i255_250_4/84f81e84cc3856f7e6e105b770ddc5f7/media/algemeen/showroom/0413103D-SCABLOK.PNG" class="img-fluid rounded thumbnail-image">
                                        </div>
                                        <div class="product-detail-container p-2">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="text-dark prodcattitle">Luxe</div>
                                                <div class="d-flex flex-column mb-2"> <span class="new-price">€ 55,99</span> <small class="old-price text-right none">$53.99</small> </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>

                    
                                <div class="col-md-3 mt-4">
                                    <div class="card card-product" v-scroll-to="'#soortHout'" v-on:click="setKeuze('typeSchutting', 'stampbeton')" v-bind:class="{'selected': (keuzes.typeSchutting == 'stampbeton')}">
                                        <div class="image-container shadow">
                                            <div class="first">
                                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-question"></i></span> </div>
                                            </div> <img src="https://cdn.mega-schutting.nl/images/i255_250_4/84f81e84cc3856f7e6e105b770ddc5f7/media/algemeen/showroom/0413103D-SCABLOK.PNG" class="img-fluid rounded thumbnail-image">
                                        </div>
                                        <div class="product-detail-container p-2">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="text-dark prodcattitle">Stampbeton</div>
                                                <div class="d-flex flex-column mb-2"> <span class="new-price">€ 84,99</span> <small class="old-price text-right none">$53.99</small> </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>
                            
                            </div>
                        </div>  
                    </div>
                </div>
            </div> 
            
        <!-- houtsoort kiezne -->
            <div class="container-fluid bg-gray container-section" id="soortHout" v-if="soortHout">
                <div class="row">
                    <div class="col-12 pl-0 pr-0" style=" margin-top:10px;  margin-bottom:40px;">

                        <div class="container mt-5 mb-5">
                            <h1 class="mb-2 font-weight-bold">Soort hout </h1><h3 class="mb-4">Kies uw gewenste houtsoort.</h3>
                            <div class="row d-flex justify-content-center">
                                
                                <div class="col-md-3 mt-4 ">
                                    <div class="card card-product h-100"  v-scroll-to="{el: cmScrollHoutsoort}"  v-on:click="setKeuze('soortHout', 'naaldhout')" v-bind:class="{'selected': (keuzes.soortHout == 'naaldhout')}">
                                        <div class="image-container shadow">
                                            <div class="first">
                                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-question"></i></span> </div>
                                            </div> <img src="images/hout-soort.png" class="img-fluid rounded thumbnail-image">
                                        </div>
                                        <div class="product-detail-container p-2">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="text-dark prodcattitle">Naaldhout</div>
                                                <div class="d-flex flex-column mb-2"> <span class="new-price">€ 24,99</span> <small class="old-price text-right none">$53.99</small> </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>
                    
                                <div class="col-md-3 mt-4 ">
                                    <div class="card card-product h-100" v-scroll-to="{el: cmScrollHoutsoort}" v-on:click="setKeuze('soortHout', 'naaldhout_zwart')" v-bind:class="{'selected': (keuzes.soortHout == 'naaldhout_zwart')}">
                                        <div class="image-container shadow">
                                            <div class="first">
                                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-question"></i></span> </div>
                                            </div> <img src="images/hout-soort.png" class="img-fluid rounded thumbnail-image">
                                        </div>
                                        <div class="product-detail-container p-2">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="text-dark prodcattitle">Naaldhout zwart</div>
                                                <div class="d-flex flex-column mb-2"> <span class="new-price">€ 28,99</span> <small class="old-price text-right none">$53.99</small> </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>
                    
                                <div class="col-md-3 mt-4 ">
                                    <div class="card card-product h-100" v-scroll-to="{el: cmScrollHoutsoort}" v-on:click="setKeuze('soortHout', 'caballero_wood')" v-bind:class="{'selected': (keuzes.soortHout == 'caballero_wood')}">
                                        <div class="image-container shadow">
                                            <div class="first">
                                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-question"></i></span> </div>
                                            </div> <img src="images/hout-soort.png" class="img-fluid rounded thumbnail-image">
                                        </div>
                                        <div class="product-detail-container p-2">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="text-dark prodcattitle">Caballero Wood® Blokhutscherm</div>
                                                <div class="d-flex flex-column mb-2"> <span class="new-price">€ 62,99</span> <small class="old-price text-right none">$53.99</small> </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-3 mt-4 ">
                                    <div class="card card-product h-100" v-scroll-to="{el: cmScrollHoutsoort}" v-on:click="setKeuze('soortHout', 'douglas')" v-bind:class="{'selected': (keuzes.soortHout == 'douglas')}">
                                        <div class="image-container shadow">
                                            <div class="first">
                                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-question"></i></span> </div>
                                            </div> <img src="images/hout-soort.png" class="img-fluid rounded thumbnail-image">
                                        </div>
                                        <div class="product-detail-container p-2">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="text-dark prodcattitle">Douglas</div>
                                                <div class="d-flex flex-column mb-2"> <span class="new-price">€ 42,99</span> <small class="old-price text-right none">$53.99</small> </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-3 mt-4 ">
                                    <div class="card card-product h-100" v-scroll-to="{el: cmScrollHoutsoort}" v-on:click="setKeuze('soortHout', 'thermowood')" v-bind:class="{'selected': (keuzes.soortHout == 'thermowood')}">
                                        <div class="image-container shadow">
                                            <div class="first">
                                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-question"></i></span> </div>
                                            </div> <img src="images/hout-soort.png" class="img-fluid rounded thumbnail-image">
                                        </div>
                                        <div class="product-detail-container p-2">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="text-dark prodcattitle">Thermowood</div>
                                                <div class="d-flex flex-column mb-2"> <span class="new-price">€ 22,99</span> <small class="old-price text-right none">$53.99</small> </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-3 mt-4 ">
                                    <div class="card card-product h-100" v-scroll-to="{el: cmScrollHoutsoort}" v-on:click="setKeuze('soortHout', 'nobifix')" v-bind:class="{'selected': (keuzes.soortHout == 'nobifix')}">
                                        <div class="image-container shadow">
                                            <div class="first">
                                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-question"></i></span> </div>
                                            </div> <img src="images/hout-soort.png" class="img-fluid rounded thumbnail-image">
                                        </div>
                                        <div class="product-detail-container p-2">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="text-dark prodcattitle">Nobifix</div>
                                                <div class="d-flex flex-column mb-2"> <span class="new-price">€ 32,99</span> <small class="old-price text-right none">$53.99</small> </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>
                    
                    
                                <div class="col-md-3 mt-4 ">
                                    <div class="card card-product h-100" v-scroll-to="{el: cmScrollHoutsoort}" v-on:click="setKeuze('soortHout', 'hardhout')" v-bind:class="{'selected': (keuzes.soortHout == 'hardhout')}">
                                        <div class="image-container shadow">
                                            <div class="first">
                                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-question"></i></span> </div>
                                            </div> <img src="images/hout-soort.png" class="img-fluid rounded thumbnail-image">
                                        </div>
                                        <div class="product-detail-container p-2">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="text-dark prodcattitle">Hardhout</div>
                                                <div class="d-flex flex-column mb-2"> <span class="new-price">€ 12,99</span> <small class="old-price text-right none">$53.99</small> </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>  
                    </div>
                </div>
            </div> 


        <!-- aantal planken kiezen -->
            <div class="container-fluid bg-gray  container-section" id="aantalPlanken" v-if="aantalPlanken">
                <div class="row">
                    <div class="col-12 pl-0 pr-0" style=" margin-top:10px;  margin-bottom:40px;">

                        <div class="container mt-5 mb-5">
                            <h1 class="mb-2 font-weight-bold">Aantal planken </h1><h3 class="mb-4">Kies het gewenste soort ligging.</h3>
                            <div class="row d-flex justify-content-center">

                                <div class="col-md-3 mt-4">
                                    <div class="card card-product" v-scroll-to="'#houtenAfdekkap'" v-on:click="setKeuze('aantalPlanken', '19')" v-bind:class="{'selected': (keuzes.aantalPlanken == '19')}">
                                        <div class="image-container shadow ">
                                            <div class="first">
                                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info-plank font-weight-bold">19 planken</span> </div>
                                            </div> <img src="images/verticaal.png" class="img-fluid rounded thumbnail-image">
                                        </div>
                                        <div class="product-detail-container p-2">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="text-dark prodcattitle">19 planken</div>
                                                <div class="d-flex flex-column mb-2"> <span class="new-price">€ 32,99</span> <small class="old-price text-right none">$53.99</small> </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>
                    
                                <div class="col-md-3 mt-4">
                                    <div class="card card-product" v-scroll-to="'#houtenAfdekkap'" v-on:click="setKeuze('aantalPlanken', '21')" v-bind:class="{'selected': (keuzes.aantalPlanken == '21')}">
                                        <div class="image-container shadow">
                                            <div class="first">
                                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info-plank font-weight-bold">21 planken</span> </div>
                                            </div> <img src="images/verticaal.png" class="img-fluid rounded thumbnail-image">
                                        </div>
                                        <div class="product-detail-container p-2">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="text-dark prodcattitle">21 planken</div>
                                                <div class="d-flex flex-column mb-2"> <span class="new-price">€ 55,99</span> <small class="old-price text-right none">$53.99</small> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>    
                                
                                <div class="col-md-3 mt-4">
                                    <div class="card card-product" v-scroll-to="'#houtenAfdekkap'" v-on:click="setKeuze('aantalPlanken', '23')" v-bind:class="{'selected': (keuzes.aantalPlanken == '23')}">
                                        <div class="image-container shadow">
                                            <div class="first">
                                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info-plank font-weight-bold">23 planken</span> </div>
                                            </div> <img src="images/verticaal.png" class="img-fluid rounded thumbnail-image">
                                        </div>
                                        <div class="product-detail-container p-2">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="text-dark prodcattitle">23 planken</div>
                                                <div class="d-flex flex-column mb-2"> <span class="new-price">€ 66,99</span> <small class="old-price text-right none">$53.99</small> </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div> 

                            </div>
                        </div>  
                    </div>
                </div>
            </div>

                                
        <!-- Houten afdekkap keuzen  -->
            <div class="container-fluid bg-gray container-section" id="houtenAfdekkap" v-if="houtenAfdekkap">
                <div class="row">
                    <div class="col-12 pl-0 pr-0" style=" margin-top:10px;  margin-bottom:40px;">

                        <div class="container mt-5 mb-5">
                            <h1 class="mb-2 font-weight-bold">Houten afdekkap </h1><h3 class="mb-4">Kies of u een afdekkap wilt.</h3>
                            <div class="row d-flex justify-content-center">
                                
                                <div class="col-md-3 mt-4">
                                    <div class="card card-product" v-scroll-to="{el: cmScrollHoutenAfdekkap}" v-on:click="setKeuze('keuzenHoutenAfdekkap', 'zonder_afdekklap')" v-bind:class="{'selected': (keuzes.keuzenHoutenAfdekkap == 'zonder_afdekklap')}">
                                        <div class="image-container ">
                                            <div class="first">
                                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-question"></i></span> </div>
                                            </div> <img src="images/logo_mega-schutting.png" class="img-fluid rounded thumbnail-image">
                                        </div>
                                        <div class="product-detail-container p-2">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="text-dark prodcattitle">Zonder afdekkap</div>
                                                <div class="d-flex flex-column mb-2"> <span class="new-price">€ 0,00</span> <small class="old-price text-right none">$53.99</small> </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>
                    
                                <div class="col-md-3 mt-4">
                                    <div class="card card-product" v-scroll-to="{el: cmScrollHoutenAfdekkap}" v-on:click="setKeuze('keuzenHoutenAfdekkap', 'met_afdekklap')" v-bind:class="{'selected': (keuzes.keuzenHoutenAfdekkap == 'met_afdekklap')}">
                                        <div class="image-container shadow">
                                            <div class="first">
                                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-question"></i></span> </div>
                                            </div> <img src="images/afdek.png" class="img-fluid rounded thumbnail-image">
                                        </div>
                                        <div class="product-detail-container p-2">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="text-dark prodcattitle">Met afdekkap</div>
                                                <div class="d-flex flex-column mb-2"> <span class="new-price">€ 32,99</span> <small class="old-price text-right none">$53.99</small> </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>  
                                
                            </div>
                        </div>  
                    </div>
                </div>
            </div>


        <!-- Motief kiezen  -->
            <div class="container-fluid bg-gray container-section" id="motief" v-if="motiefKiezen">
                <div class="row">
                    <div class="col-12 pl-0 pr-0" style=" margin-top:10px;  margin-bottom:40px;">

                        <div class="container mt-5 mb-5">
                            <h1 class="mb-2 font-weight-bold">Keuzen motief </h1><h3 class="mb-4">Kies het gewenste motief.</h3>
                            <div class="row d-flex justify-content-center">
                                
                                <div class="col-md-3 mt-4">
                                    <div class="card card-product h-100" v-scroll-to="'#kleurBeton'" v-on:click="setKeuze('soortMotief', 'klassieke_steen_motief')" v-bind:class="{'selected': (keuzes.soortMotief == 'klassieke_steen_motief')}">
                                        <div class="image-container ">
                                            <div class="first">
                                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-question"></i></span> </div>
                                            </div> <img src="images/betonplaat/steen-motief.png" class="img-fluid rounded thumbnail-image">
                                        </div>
                                        <div class="product-detail-container p-2">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="text-dark prodcattitle">Betonplaat Klassieke steen-motief</div>
                                                <div class="d-flex flex-column mb-2"> <span class="new-price">€ 53,00</span> <small class="old-price text-right none">$53.99</small> </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-3 mt-4">
                                    <div class="card card-product " v-scroll-to="'#kleurBeton'" v-on:click="setKeuze('soortMotief', 'rots_motief')" v-bind:class="{'selected': (keuzes.soortMotief == 'rots_motief')}">
                                        <div class="image-container ">
                                            <div class="first">
                                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-question"></i></span> </div>
                                            </div> <img src="images/betonplaat/rots-motief.png" class="img-fluid rounded thumbnail-image">
                                        </div>
                                        <div class="product-detail-container p-2">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="text-dark prodcattitle">Betonplaatr rots-motief</div>
                                                <div class="d-flex flex-column mb-2"> <span class="new-price">€ 52,00</span> <small class="old-price text-right none">$53.99</small> </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-3 mt-4">
                                    <div class="card card-product" v-scroll-to="'#kleurBeton'" v-on:click="setKeuze('soortMotief', 'hout_motief')" v-bind:class="{'selected': (keuzes.soortMotief == 'hout_motief')}">
                                        <div class="image-container">
                                            <div class="first">
                                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-question"></i></span> </div>
                                            </div> <img src="images/betonplaat/hout-motief.png" class="img-fluid rounded thumbnail-image">
                                        </div>
                                        <div class="product-detail-container p-2">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="text-dark prodcattitle">Betonplaat hout-motief</div>
                                                <div class="d-flex flex-column mb-2"> <span class="new-price">€ 67,00</span> <small class="old-price text-right none">$53.99</small> </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-3 mt-4">
                                    <div class="card card-product" v-scroll-to="'#kleurBeton'" v-on:click="setKeuze('soortMotief', 'elbe_motief')" v-bind:class="{'selected': (keuzes.soortMotief == 'elbe_motief')}">
                                        <div class="image-container ">
                                            <div class="first">
                                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-question"></i></span> </div>
                                            </div> <img src="images/betonplaat/Elbe-motief.png" class="img-fluid rounded thumbnail-image">
                                        </div>
                                        <div class="product-detail-container p-2">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="text-dark prodcattitle">Betonplaat Elbe-motief</div>
                                                <div class="d-flex flex-column mb-2"> <span class="new-price">€ 63,00</span> <small class="old-price text-right none">$53.99</small> </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-3 mt-4">
                                    <div class="card card-product" v-scroll-to="'#kleurBeton'" v-on:click="setKeuze('soortMotief', 'glad')" v-bind:class="{'selected': (keuzes.soortMotief == 'glad')}">
                                        <div class="image-container ">
                                            <div class="first">
                                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-question"></i></span> </div>
                                            </div> <img src="images/betonplaat/glad.png" class="img-fluid rounded thumbnail-image">
                                        </div>
                                        <div class="product-detail-container p-2">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="text-dark prodcattitle">Betonplaat glad</div>
                                                <div class="d-flex flex-column mb-2"> <span class="new-price">€ 48,00</span> <small class="old-price text-right none">$53.99</small> </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-3 mt-4">
                                    <div class="card card-product" v-scroll-to="'#kleurBeton'" v-on:click="setKeuze('soortMotief', 'graniet_motief')" v-bind:class="{'selected': (keuzes.soortMotief == 'graniet_motief')}">
                                        <div class="image-container ">
                                            <div class="first">
                                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-question"></i></span> </div>
                                            </div> <img src="images/betonplaat/graniet-motief.png" class="img-fluid rounded thumbnail-image">
                                        </div>
                                        <div class="product-detail-container p-2">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="text-dark prodcattitle">Betonplaat graniet-motief</div>
                                                <div class="d-flex flex-column mb-2"> <span class="new-price">€ 60,00</span> <small class="old-price text-right none">$53.99</small> </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-3 mt-4">
                                    <div class="card card-product" v-scroll-to="'#kleurBeton'" v-on:click="setKeuze('soortMotief', 'lariks_motief')" v-bind:class="{'selected': (keuzes.soortMotief == 'lariks_motief')}">
                                        <div class="image-container ">
                                            <div class="first">
                                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-question"></i></span> </div>
                                            </div> <img src="images/betonplaat/Lariks-motief.png" class="img-fluid rounded thumbnail-image">
                                        </div>
                                        <div class="product-detail-container p-2">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="text-dark prodcattitle">Betonplaat Lariks-motief</div>
                                                <div class="d-flex flex-column mb-2"> <span class="new-price">€ 73,00</span> <small class="old-price text-right none">$53.99</small> </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-3 mt-4">
                                    <div class="card card-product" v-scroll-to="'#kleurBeton'" v-on:click="setKeuze('soortMotief', 'nostalgie_motief')" v-bind:class="{'selected': (keuzes.soortMotief == 'nostalgie_motief')}">
                                        <div class="image-container ">
                                            <div class="first">
                                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-question"></i></span> </div>
                                            </div> <img src="images/betonplaat/Nostalgie-motief.png" class="img-fluid rounded thumbnail-image">
                                        </div>
                                        <div class="product-detail-container p-2">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="text-dark prodcattitle">Betonplaat Nostalgie-motief</div>
                                                <div class="d-flex flex-column mb-2"> <span class="new-price">€ 68,00</span> <small class="old-price text-right none">$53.99</small> </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-3 mt-4">
                                    <div class="card card-product" v-scroll-to="'#kleurBeton'" v-on:click="setKeuze('soortMotief', 'romanico_motief')" v-bind:class="{'selected': (keuzes.soortMotief == 'romanico_motief')}">
                                        <div class="image-container ">
                                            <div class="first">
                                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-question"></i></span> </div>
                                            </div> <img src="images/betonplaat/Romanico-motief.png" class="img-fluid rounded thumbnail-image">
                                        </div>
                                        <div class="product-detail-container p-2">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="text-dark prodcattitle">Betonplaat Romanico-motief</div>
                                                <div class="d-flex flex-column mb-2"> <span class="new-price">€ 58,00</span> <small class="old-price text-right none">$53.99</small> </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>
                    
                                
                                
                            </div>
                        </div>  
                    </div>
                </div>
            </div>


        <!-- betonplaat kleur kiezen   -->
            <div class="container-fluid bg-gray container-section" id="kleurBeton" v-if="kleurBeton">
                <div class="row">
                    <div class="col-12 pl-0 pr-0" style=" margin-top:10px;  margin-bottom:40px;">

                        <div class="container mt-5 mb-5">
                            <h1 class="mb-2 font-weight-bold">Kleur beton </h1><h3 class="mb-4">Kies het gewenste betonplaat.</h3>
                            <div class="row d-flex justify-content-center">
                                
                                <div class="col-md-3 mt-4">
                                    <div class="card card-product" v-scroll-to="'#betonenAfdekkap'"  v-on:click="setKeuze('betonKleur', 'kleur_wit')" v-bind:class="{'selected': (keuzes.betonKleur == 'kleur_wit')}">
                                        <div class="image-container shadow ">
                                            <div class="first">
                                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info-color info-color-1 font-weight-bold"></span> </div>
                                            </div> <img src="images/plaat-wit.png" class="img-fluid rounded thumbnail-image">
                                        </div>
                                        <div class="product-detail-container p-2">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="text-dark prodcattitle">Kleur wit</div>
                                                <div class="d-flex flex-column mb-2"> <span class="new-price">€ 32,99</span> <small class="old-price text-right none">$53.99</small> </div>
                                    </div>
                                        
                                        </div>
                                    </div>
                                </div>
                    
                                <div class="col-md-3 mt-4">
                                    <div class="card card-product" v-scroll-to="'#betonenAfdekkap'" v-on:click="setKeuze('betonKleur', 'kleur_grijs')" v-bind:class="{'selected': (keuzes.betonKleur == 'kleur_grijs')}">
                                        <div class="image-container shadow">
                                            <div class="first">
                                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info-color info-color-2 font-weight-bold"></span> </div>
                                            </div> <img src="images/plaat-wit.png" class="img-fluid rounded thumbnail-image">
                                        </div>
                                        <div class="product-detail-container p-2">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="text-dark prodcattitle">Kleur grijs</div>
                                                <div class="d-flex flex-column mb-2"> <span class="new-price">€ 32,99</span> <small class="old-price text-right none">$53.99</small>
                                            </div>
                                    </div>
                                        
                                        </div>
                                    </div>
                                </div>    
                                
                                <div class="col-md-3 mt-4">
                                    <div class="card card-product" v-scroll-to="'#betonenAfdekkap'" v-on:click="setKeuze('betonKleur', 'kleur_antracite')" v-bind:class="{'selected': (keuzes.betonKleur == 'kleur_antracite')}">
                                        <div class="image-container shadow">
                                            <div class="first">
                                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info-color info-color-3 font-weight-bold"></span> </div>
                                            </div> <img src="images/plaat-wit.png" class="img-fluid rounded thumbnail-image">
                                        </div>
                                        <div class="product-detail-container p-2">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="text-dark prodcattitle">Kleur antracite</div>
                                                <div class="d-flex flex-column mb-2"> <span class="new-price">€ 32,99</span> <small class="old-price text-right none">$53.99</small> </div>
                                    </div>
                                        
                                        </div>
                                    </div>
                                </div> 

                                <div class="col-md-3 mt-4">
                                    <div class="card card-product" v-scroll-to="'#betonenAfdekkap'" v-on:click="setKeuze('betonKleur', 'kleur_taupe')" v-bind:class="{'selected': (keuzes.betonKleur == 'kleur_taupe')}">
                                        <div class="image-container shadow">
                                            <div class="first">
                                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info-color info-color-4 font-weight-bold"></span> </div>
                                            </div> <img src="images/plaat-wit.png" class="img-fluid rounded thumbnail-image">
                                        </div>
                                        <div class="product-detail-container p-2">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="text-dark prodcattitle">Kleur taupe</div>
                                                <div class="d-flex flex-column mb-2"> <span class="new-price">€ 32,99</span> <small class="old-price text-right none">$53.99</small> </div>
                                    </div>
                                        
                                        </div>
                                    </div>
                                </div> 
                    
                            </div>
                        </div>  
                    </div>
                </div>
            </div> 

  
        <!-- Betonen afdekkap keuzen  -->
            <div class="container-fluid bg-gray container-section" id="betonenAfdekkap" v-if="betonAfdekkap">
                <div class="row">
                    <div class="col-12 pl-0 pr-0" style=" margin-top:10px;  margin-bottom:40px;">

                        <div class="container mt-5 mb-5">
                            <h1 class="mb-2 font-weight-bold">Betonnen afdekkap </h1><h3 class="mb-4">Kies als u een beton afdekkap wilt.</h3>
                            <div class="row d-flex justify-content-center">
                                
                                <div class="col-md-3 mt-4">
                                    <div class="card card-product" v-scroll-to="'#keuzenPoort'" v-on:click="setKeuze('keuzenBetonenAfdekkap', 'zonder_afdekklap')" v-bind:class="{'selected': (keuzes.keuzenBetonenAfdekkap == 'zonder_afdekklap')}">
                                        <div class="image-container ">
                                            <div class="first">
                                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-question"></i></span> </div>
                                            </div> <img src="images/logo_mega-schutting.png" class="img-fluid rounded thumbnail-image">
                                        </div>
                                        <div class="product-detail-container p-2">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="text-dark prodcattitle">Zonder afdekkap</div>
                                                <div class="d-flex flex-column mb-2"> <span class="new-price">€ 0,00</span> <small class="old-price text-right none">$53.99</small> </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>
                    
                                <div class="col-md-3 mt-4">
                                    <div class="card card-product" v-scroll-to="'#keuzenPoort'" v-on:click="setKeuze('keuzenBetonenAfdekkap', 'met_afdekklap')" v-bind:class="{'selected': (keuzes.keuzenBetonenAfdekkap == 'met_afdekklap')}">
                                        <div class="image-container shadow ">
                                            <div class="first">
                                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-question"></i></span> </div>
                                            </div> <img src="images/beton-afdek.png" class="img-fluid rounded thumbnail-image">
                                        </div>
                                        <div class="product-detail-container p-2">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="text-dark prodcattitle">Met afdekkap</div>
                                                <div class="d-flex flex-column mb-2"> <span class="new-price">€ 16,99</span> <small class="old-price text-right none">$53.99</small> </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>  
                                
                            </div>
                        </div>  
                    </div>
                </div>
            </div>
                
   
        <!-- Poort keuzen -->
            <div class="container-fluid bg-gray container-section" id="keuzenPoort" v-if="keuzenPoort">
                <div class="row">
                    <div class="col-12 pl-0 pr-0" style=" margin-top:10px;  margin-bottom:40px;">

                        <div class="container mt-5 mb-5">
                            <h1 class="mb-2 font-weight-bold">Keuzen poort </h1><h3 class="mb-4">Kies als u een poort wilt.</h3>
                            <div class="row">
                                
                                <div class="col-md-3 mt-4">
                                    <div class="card card-product" v-scroll-to="'#offerte'" v-on:click="setKeuze('keuzenPoort', 'zonder_poort')" v-bind:class="{'selected': (keuzes.keuzenPoort == 'zonder_poort')}">
                                        <div class="image-container shadow">
                                            <div class="first">
                                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-question"></i></span> </div>
                                            </div> <img src="images/logo_mega-schutting.png" class="img-fluid rounded thumbnail-image">
                                        </div>
                                        <div class="product-detail-container p-2">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="text-dark prodcattitle">Zonder poort</div>
                                                <div class="d-flex flex-column mb-2"> <span class="new-price">€ 0,00</span> <small class="old-price text-right none">$53.99</small> </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>
                    
                                <div class="col-md-3 mt-4">
                                    <div class="card card-product" v-scroll-to="{el: cmScrollPoort}" v-on:click="setKeuze('keuzenPoort', 'poort_90cm')" v-bind:class="{'selected': (keuzes.keuzenPoort == 'poort_90cm')}">
                                        <div class="image-container shadow">
                                            <div class="first">
                                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-question"></i></span> </div>
                                            </div> <img src="images/poort.png" class="img-fluid rounded thumbnail-image">
                                        </div>
                                        <div class="product-detail-container p-2">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="text-dark prodcattitle">Poort 90cm</div>
                                                <div class="d-flex flex-column mb-2"> <span class="new-price">€ 10,00</span> <small class="old-price text-right none">$53.99</small> </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>
                    
                                <div class="col-md-3 mt-4">
                                    <div class="card card-product " v-scroll-to="{el: cmScrollPoort}" v-on:click="setKeuze('keuzenPoort', 'poort_100cm')" v-bind:class="{'selected': (keuzes.keuzenPoort == 'poort_100cm')}">
                                        <div class="image-container shadow">
                                            <div class="first">
                                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-question"></i></span> </div>
                                            </div> <img src="images/poort.png" class="img-fluid rounded thumbnail-image">
                                        </div>
                                        <div class="product-detail-container p-2">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="text-dark prodcattitle">Poort 100cm</div>
                                                <div class="d-flex flex-column mb-2"> <span class="new-price">€ 32,99</span> <small class="old-price text-right none">$53.99</small> </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-3 mt-4">
                                    <div class="card card-product" v-scroll-to="'#offerte'" v-on:click="setKeuze('keuzenPoort', 'poort_120cm')" v-bind:class="{'selected': (keuzes.keuzenPoort == 'poort_120cm')}">
                                        <div class="image-container shadow">
                                            <div class="first">
                                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-question"></i></span> </div>
                                            </div> <img src="images/poort.png" class="img-fluid rounded thumbnail-image">
                                        </div>
                                        <div class="product-detail-container p-2">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="text-dark prodcattitle">Poort 120cm</div>
                                                <div class="d-flex flex-column mb-2"> <span class="new-price">€ 32,99</span> <small class="old-price text-right none">$53.99</small> </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>  
                    </div>
                </div>
            </div> 

 
        <!-- Poort soort kiezen -->
            <div class="container-fluid container-section" id="soortPoort" v-if="(keuzes.keuzenPoort == 'poort_90cm' && keuzes.soortScherm == 'volledig_beton'  ||  keuzes.keuzenPoort == 'poort_100cm' && keuzes.soortScherm == 'volledig_beton' ||  keuzes.keuzenPoort == 'poort_120cm' && keuzes.soortScherm == 'volledig_beton')">
                <div class="row">
                    <div class="col-12 pl-0 pr-0" style=" margin-top:10px;  margin-bottom:40px;">

                        <div class="container mt-5 mb-5">
                            <h1 class="mb-2 font-weight-bold">Soort poort </h1><h3 class="mb-4">Kies het gewenste soort poort.</h3>
                            <div class="row d-flex justify-content-center">
                                
                                <div class="col-md-4 mt-4">
                                    <div class="card card-product" v-scroll-to="'#offerte'" v-on:click="setKeuze('soortPoort', 'caballero_wood_poort')" v-bind:class="{'selected': (keuzes.soortPoort == 'caballero_wood_poort')}">
                                        <div class="image-container shadow">
                                            <div class="first">
                                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-question"></i></span> </div>
                                            </div> <img src="images/port/cab-poort.png" class="img-fluid rounded thumbnail-image">
                                        </div>
                                        <div class="product-detail-container p-2">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="text-dark prodcattitle">Caballero Wood® poort</div>
                                                <div class="d-flex flex-column mb-2"> <span class="new-price">€ 0,00</span> <small class="old-price text-right none">$53.99</small> </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>
                    
                                
                                <div class="col-md-4 mt-4">
                                    <div class="card card-product" v-scroll-to="'#offerte'" v-on:click="setKeuze('soortPoort', 'douglas_poort')" v-bind:class="{'selected': (keuzes.soortPoort == 'douglas_poort')}">
                                        <div class="image-container shadow">
                                            <div class="first">
                                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-question"></i></span> </div>
                                            </div> <img src="images/port/doug-poort.png" class="img-fluid rounded thumbnail-image">
                                        </div>
                                        <div class="product-detail-container p-2">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="text-dark prodcattitle">Douglas poort</div>
                                                <div class="d-flex flex-column mb-2"> <span class="new-price">€ 29,99</span> <small class="old-price text-right none">$53.99</small> </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div> 

                                
                                <div class="col-md-4 mt-4">
                                    <div class="card card-product" v-scroll-to="'#offerte'" v-on:click="setKeuze('soortPoort', 'hardhout_poort')" v-bind:class="{'selected': (keuzes.soortPoort == 'hardhout_poort')}">
                                        <div class="image-container shadow">
                                            <div class="first">
                                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-question"></i></span> </div>
                                            </div> <img src="images/port/hard-poort.png" class="img-fluid rounded thumbnail-image">
                                        </div>
                                        <div class="product-detail-container p-2">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="text-dark prodcattitle">Hardhout poort</div>
                                                <div class="d-flex flex-column mb-2"> <span class="new-price">€ 34,99</span> <small class="old-price text-right none">$53.99</small> </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>

                                
                                <div class="col-md-4 mt-4">
                                    <div class="card card-product" v-scroll-to="'#offerte'" v-on:click="setKeuze('soortPoort', 'naaldhout_poort')" v-bind:class="{'selected': (keuzes.soortPoort == 'naaldhout_poort')}" >
                                        <div class="image-container shadow">
                                            <div class="first">
                                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-question"></i></span> </div>
                                            </div> <img src="images/port/naald-poort.png" class="img-fluid rounded thumbnail-image">
                                        </div>
                                        <div class="product-detail-container p-2">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="text-dark prodcattitle">Naaldhout geimpregneerd poort</div>
                                                <div class="d-flex flex-column mb-2"> <span class="new-price">€ 27,99</span> <small class="old-price text-right none">$53.99</small> </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>

                                
                                <div class="col-md-4 mt-4">
                                    <div class="card card-product" v-scroll-to="'#offerte'" v-on:click="setKeuze('soortPoort', 'zwarte_poort')" v-bind:class="{'selected': (keuzes.soortPoort == 'zwarte_poort')}" >
                                        <div class="image-container shadow">
                                            <div class="first">
                                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-question"></i></span> </div>
                                            </div> <img src="images/port/zwart-poort.png" class="img-fluid rounded thumbnail-image">
                                        </div>
                                        <div class="product-detail-container p-2">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="text-dark prodcattitle">Zwarte poort</div>
                                                <div class="d-flex flex-column mb-2"> <span class="new-price">€ 12,99</span> <small class="old-price text-right none">$53.99</small> </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>

                                
                                
                            </div>
                        </div>  
                    </div>
                </div>
            </div>

</div>
</div>
                           
                         

    <!-- Overzicht offerte -->
        <div class="container container-offerte bg-light mt-5 mb-5 p-5" id="offerte" v-if="(keuzes.typeAanvraag == 'snel_offerte' )">
            <h3 class="text-center pb-3 font-weight-bold">Overzicht offerte.</h3>
            <div class=""><span class="item-price "><span class="dollar">Totaal: </span> €638,97</span></div>
            <div class="row">
                
                <div class="col-md-2 mt-4" v-scroll-to="'#aantal_hoeken'">
                    <div class="card card-product h-100">
                        <div class="image-container shadow">
                            <div class="first">
                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-hammer"></i></span> </div>
                            </div> <img src="images/hoek_1.jpg" class="img-fluid rounded thumbnail-image">
                        </div>
                        <div class="product-detail-container p-2">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="d-flex flex-column mb-2">Aantaal hoeken: 1 <span class="new-price">5m x 12m</span> <small class="old-price text-right none">$53.99</small> </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-2 mt-4" v-scroll-to="'#paal'">
                    <div class="card card-product h-100">
                        <div class="image-container shadow">
                            <div class="first">
                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-hammer"></i></span> </div>
                            </div> <img src="images/beton.png" class="img-fluid rounded thumbnail-image">
                        </div>
                        <div class="product-detail-container p-2">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="d-flex flex-column mb-2">Betonen paal <span class="new-price">$92.99</span> <small class="old-price text-right none">$53.99</small> </div>
                            </div>
                        </div>
                    </div>
                </div>    
                
                <div class="col-md-2 mt-4">
                    <div class="card card-product h-100">
                        <div class="image-container shadow">
                            <div class="first">
                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-hammer"></i></span> </div>
                            </div> <img src="images/verticaal.png" class="img-fluid rounded thumbnail-image">
                        </div>
                        <div class="product-detail-container p-2">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="d-flex flex-column mb-2">Verticaal scherm <span class="new-price">$92.99</span> <small class="old-price text-right none">$53.99</small> </div>
                            </div>
                        </div>
                    </div>
                </div> 
                <div class="col-md-2 mt-4">
                    <div class="card card-product h-100">
                        <div class="image-container shadow">
                            <div class="first">
                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-hammer"></i></span> </div>
                            </div> <img src="https://cdn.mega-schutting.nl/images/i255_250_4/84f81e84cc3856f7e6e105b770ddc5f7/media/algemeen/showroom/0413103D-SCABLOK.PNG" class="img-fluid rounded thumbnail-image">
                        </div>
                        <div class="product-detail-container p-2">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="d-flex flex-column mb-2">Standaart <span class="new-price">$92.99</span> <small class="old-price text-right none">$53.99</small> </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-2 mt-4">
                    <div class="card card-product h-100">
                        <div class="image-container shadow">
                            <div class="first">
                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-hammer"></i></span> </div>
                            </div> <img src="images/hout-soort.png" class="img-fluid rounded thumbnail-image">
                        </div>
                        <div class="product-detail-container p-2">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="d-flex flex-column mb-2">Calballero Wood® Blokhutscherm <span class="new-price">$92.99</span> <small class="old-price text-right none">$53.99</small> </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-2 mt-4">
                    <div class="card card-product h-100">
                        <div class="image-container shadow">
                            <div class="first">
                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-hammer"></i></span> </div>
                            </div> <img src="images/verticaal.png" class="img-fluid rounded thumbnail-image">
                        </div>
                        <div class="product-detail-container p-2">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="d-flex flex-column mb-2">Verticaal 21 planken <span class="new-price">$92.99</span> <small class="old-price text-right none">$53.99</small> </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-2 mt-4">
                    <div class="card card-product h-100">
                        <div class="image-container shadow">
                            <div class="first">
                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-hammer"></i></span> </div>
                            </div> <img src="images/afdek.png" class="img-fluid rounded thumbnail-image">
                        </div>
                        <div class="product-detail-container p-2">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="d-flex flex-column mb-2">Met afdekklap <span class="new-price">$92.99</span> <small class="old-price text-right none">$53.99</small> </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-2 mt-4">
                    <div class="card card-product h-100">
                        <div class="image-container shadow">
                            <div class="first">
                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-hammer"></i></span> </div>
                            </div> <img src="images/betonplaat/hout-motief.png" class="img-fluid rounded thumbnail-image">
                        </div>
                        <div class="product-detail-container p-2">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="d-flex flex-column mb-2">Betonplaat hout-motief <span class="new-price">$92.99</span> <small class="old-price text-right none">$53.99</small> </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="col-md-2 mt-4">
                    <div class="card card-product h-100">
                        <div class="image-container shadow">
                            <div class="first">
                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-hammer"></i></span> </div>
                            </div> <img src="images/plaat-wit.png" class="img-fluid rounded thumbnail-image">
                        </div>
                        <div class="product-detail-container p-2">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="d-flex flex-column mb-2">Kleur grijs <span class="new-price">$92.99</span> <small class="old-price text-right none">$53.99</small> </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-2 mt-4">
                    <div class="card card-product h-100">
                        <div class="image-container shadow">
                            <div class="first">
                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-hammer"></i></span> </div>
                            </div> <img src="images/beton-afdek.png" class="img-fluid rounded thumbnail-image">
                        </div>
                        <div class="product-detail-container p-2">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="d-flex flex-column mb-2">Met afdekklap <span class="new-price">$92.99</span> <small class="old-price text-right none">$53.99</small> </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-2 mt-4">
                    <div class="card card-product h-100">
                        <div class="image-container shadow">
                            <div class="first">
                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-hammer"></i></span> </div>
                            </div> <img src="images/poort.png" class="img-fluid rounded thumbnail-image">
                        </div>
                        <div class="product-detail-container p-2">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="d-flex flex-column mb-2">Poort 100cm <span class="new-price">$92.99</span> <small class="old-price text-right none">$53.99</small> </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-2 mt-4">
                    <div class="card card-product h-100">
                        <div class="image-container shadow">
                            <div class="first">
                                <div class="d-flex justify-content-between align-items-center"> <span class="discount none-op">-25%</span> <span class="info"><i class="fas fa-hammer"></i></span> </div>
                            </div> <img src="images/port/doug-poort.png" class="img-fluid rounded thumbnail-image">
                        </div>
                        <div class="product-detail-container p-2">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="d-flex flex-column mb-2">Douglas poort <span class="new-price">$92.99</span> <small class="old-price text-right none">$53.99</small> </div>
                            </div>
                        </div>
                    </div>
                </div>

                
                <div class="container mt-4">
                    <section id="contact" class="gray-bg padding-top-bottom mt-2">
                        <div class="container ">
                            <div class="row d-flex mb-3">
                                <p v-on:click="setOfferte('offerte', 'vraag')" class="col-md-3"><button class="btn btn-success btn-block mt-4" ><i class="fas fa-question"></i> Offerte vraag stellen </button></p>
                                <!-- <p v-on:click="setOfferte('offerte', 'toevoegen')" class="col-md-4"><button class="btn btn-success btn-block mt-4" ><i class="fas fa-cart-plus"></i> Offerte toevoegen aan winkelwagen <i class="fas fa-cart-plus"></i></button></p> -->
                                <p v-on:click="setOfferte('offerte', 'bestellen')" class="col-md-3"><button class="btn btn-success btn-block mt-4" ><i class="fas fa-cart-arrow-down"></i> Offerte Bestellen </button></p>
                            </div>

                            <div class="row"  v-bind:class="{'d-none': (offertes.offerte != 'vraag')}">
                                <form id="Highlighted-form" class="col-md-12 col-sm-offset-3" action="#" method="post" novalidate="">
                                    
                                <div class="row">
                                    <div class="form-group col-md-4">
                                        <label class="control-label" for="contact-name"><i class="fa fa-user text-success"></i> Naam:</label>
                                        <div class="controls">
                                        <input id="contact-name" name="contactName" placeholder="Uw naam" class="form-control requiredField Highlighted-label" data-new-placeholder="Uw naam" type="text" data-error-empty="Vul alstublieft uw naam in.">
                                        </div>
                                    </div><!-- End name input -->
                                    
                                    <div class="form-group col-md-4">
                                        <label class="control-label" for="contact-mail"><i class="fa fa-envelope text-success"></i> Email:</label>
                                        <div class=" controls">
                                        <input id="contact-mail" name="email" placeholder="Uw email" class="form-control requiredField Highlighted-label" data-new-placeholder="Uw email" type="email" data-error-empty="Vul alstublieft uw email in." data-error-invalid="Ongeldig email addres">
                                        </div>
                                    </div><!-- End email input -->

                                     <div class="form-group col-md-4">
                                        <label class="control-label" for="contact-name"><i class="fas fa-phone-alt text-success"></i> Telefoon:</label>
                                        <div class="controls">
                                        <input id="contact-name" name="contactName" placeholder="Uw nummer" class="form-control requiredField Highlighted-label" data-new-placeholder="Uw naam" type="text" data-error-empty="Vul alstublieft uw telefoon nummer in.">
                                        </div>
                                    </div><!-- End name input -->
                                </div>

                                    <div class="form-group">
                                    <label class="control-label" for="contact-message"><i class="fa fa-comment text-success"></i> Vraag: </label>
                                        <div class="controls">
                                            <textarea id="contact-message" name="comments" placeholder="Uw vraag en/of bericht" class="form-control requiredField Highlighted-label" data-new-placeholder="Uw vraag en/of bericht" rows="6" data-error-empty="Please enter your message"></textarea>
                                        
                                        </div>
                                    </div><!-- End textarea -->

                                    <p><button name="submit" type="submit" class="btn btn-success btn-block mt-4" data-error-message="Error!" data-sending-message="Sending..." data-ok-message="Message Sent"><i class="fa fa-location-arrow "></i> Bericht versturen</button></p>
                                    <input type="hidden" name="submitted" id="submitted" value="true">	
                                </form><!-- End Highlighted-form -->
   
                            </div>	


                        </div>	
                    </section>
                    </div>    
                
            </div>
        </div>  


    <!-- Offerte pop-up message -->
    <input type="checkbox" id="check"> <label class="chat-btn position-fixed " for="check"> <i class="fa fa-commenting-o comment">Totaal: € 638,97</i> <i class="fas fa-times-circle close"></i> </label>
    <div class="wrapper wrapper-cart mt-3">
        <div class="header-cart font-weight-bold">
            <h4 class="font-weight-bold text-center">Overzicht offerte</h4>
        </div>

        <div class="container container-cart">
            <div class="mb-3 mt-1 ">
               <div class="pl-3 pt-3">Matrialen: <span class="float-right pr-3">€ 243,35</span></div> 
               <div class="pl-3 pt-2">Montage: <span class="float-right pr-3">€ 182,42</span></div> 
               <div class="pl-3 pt-2">Subtotaal: <span class="float-right pr-3">€ 412,42</span></div> 
               <div class="pl-3 pt-2">BTW: <span class="float-right pr-3">€ 226,24</span></div>
               <div class="pl-3 pt-2 pb-3"><p class="font-weight-bold">Totaal <span class="float-right pr-3">€ 638,97</span></p></div> 
            </div>
        </div>
        
                                        
    </div>


</main>
</template>

<script>
export default {
  name: 'HelloWorld',
  data () {
     return {
         keuzes: {
             typeAanvraag: '',
             aantalZijdes: '',
             lengtes: {
                 'zijde_a': 0,
                 'zijde_b': 0,
                 'zijde_c': 0,
                 'zijde_d': 0
             },
             soortPaal: '',
             soortScherm: '',
             typeSchutting: '',
             soortHout: '',
             aantalPlanken: '',
             keuzenHoutenAfdekkap: '',
             soortMotief: '',
             betonKleur: '',
             keuzenBetonenAfdekkap: '',
             keuzenPoort: '',
             soortPoort: '',
         },
         alertLengteA: false,
         alertLengteB: false,
         alertLengteC: false,
         alertLengteD: false,
         offertes:{
             offerte: '',
         },
     }
  },
  
  methods: {
      setKeuze: function(variabel, value){
        //   this.{variabel} = value;
        this.keuzes[variabel] = value;
        console.log("variabel " + variabel);
        console.log("value " + value);

        console.log("Data nu", this.keuzes);

      },
      setOfferte: function(variabel, value){
        //   this.{variabel} = value;
        this.offertes[variabel] = value;
        
      },
      naarPalen: function(){
          if (this.keuzes.aantalZijdes >= 1 && this.keuzes.lengtes.zijde_a == '0') {
              this.alertLengteA = true;
          } else if(this.keuzes.aantalZijdes >= 1 && this.keuzes.lengtes.zijde_a != '0') {
              this.alertLengteA = false;
          } if (this.keuzes.aantalZijdes >= 2 && this.keuzes.lengtes.zijde_a == '0' ) {
              this.alertLengteA = true;
              this.alertLengteB = false;
          } else if(this.keuzes.aantalZijdes >= 2 && this.keuzes.lengtes.zijde_b == '0') {
              this.alertLengteA = false;
              this.alertLengteB = true;
          } else if(this.keuzes.aantalZijdes >= 2 && this.keuzes.lengtes.zijde_b != '0') {
              this.alertLengteB = false;
          } if (this.keuzes.aantalZijdes >= 3 && this.keuzes.lengtes.zijde_a == '0' ) {
              this.alertLengteA = true;
              this.alertLengteB = false;
              this.alertLengteC = false;
          } else if(this.keuzes.aantalZijdes >= 3 && this.keuzes.lengtes.zijde_b == '0') {
              this.alertLengteA = false;
              this.alertLengteB = true;
              this.alertLengteC = false;
          }else if(this.keuzes.aantalZijdes >= 3 && this.keuzes.lengtes.zijde_c == '0') {
              this.alertLengteA = false;
              this.alertLengteB = false;
              this.alertLengteC = true;
          } else if(this.keuzes.aantalZijdes >= 3 && this.keuzes.lengtes.zijde_c != '0') {
              this.alertLengteC = false;
          } if (this.keuzes.aantalZijdes >= 4 && this.keuzes.lengtes.zijde_a == '0' ) {
              this.alertLengteA = true;
              this.alertLengteB = false;
              this.alertLengteC = false;
              this.alertLengteD = false;
          } else if(this.keuzes.aantalZijdes >= 4 && this.keuzes.lengtes.zijde_b == '0') {
              this.alertLengteA = false;
              this.alertLengteB = true;
              this.alertLengteC = false;
              this.alertLengteD = false;
          }else if(this.keuzes.aantalZijdes >= 4 && this.keuzes.lengtes.zijde_c == '0') {
              this.alertLengteA = false;
              this.alertLengteB = false;
              this.alertLengteC = true;
              this.alertLengteD = false;
          }
          else if(this.keuzes.aantalZijdes >= 4 && this.keuzes.lengtes.zijde_d == '0') {
              this.alertLengteA = false;
              this.alertLengteB = false;
              this.alertLengteC = false;
              this.alertLengteD = true;
          } else if(this.keuzes.aantalZijdes >= 4 && this.keuzes.lengtes.zijde_d != '0') {
              this.alertLengteD = false;
          } else{
              return '#schutting';
          }
          
      }


  },
  computed: {
    // a computed getter
     cmScrollInput(){
        if((this.alertLengteA == true || this.alertLengteB == true || this.alertLengteC == true || this.alertLengteD == true)){
            return '#niks';
        }
      return '#paal';
    },
     cmScrollScherm(){
        if((this.typeSchutting == false)){
            return '#soortHout';
        }
      return '#schutting';
    },
    cmScrollHoutsoort(){
        if((this.kleurBeton == true)){
            return '#kleurBeton';
        }
      return '#aantalPlanken';
    },
    cmScrollHoutenAfdekkap(){
        if((this.keuzenPoort == true)){
            return '#keuzenPoort';
        }else if((this.kleurBeton == true)) {
            return '#kleurBeton';
        }else{
          return '#motief';
        }
      
    },
    cmScrollPoort(){
        if((this.soortPoort == true)){
            return '#soortPoort';
        }
      return '#offerte';
    },
    schermKiezen() {
        if((this.keuzes.soortPaal != '')){
            return true;
        }
      return false;
    },
    typeSchutting(){
        if((this.keuzes.soortPaal == 'hout')){
            return false;
        }else if((this.keuzes.soortScherm != '' && this.keuzes.soortScherm == 'verticaal' || this.keuzes.soortScherm == 'horizontaal')) {
            return true;

        }else{
        return false;
        }
    },

    soortHout() {
       
        if((this.keuzes.typeSchutting != '' && this.keuzes.soortScherm == 'volledig_beton' )){
          return false;
        }else if((this.keuzes.typeSchutting != '' || this.keuzes.soortScherm == 'dicht_blokhut' && this.keuzes.soortPaal == 'beton')) {
            return true;
        }else if((this.keuzes.typeSchutting == '' && this.keuzes.soortScherm == 'verticaal' || this.keuzes.typeSchutting == '' && this.keuzes.soortScherm == 'horizontaal')) {
            if (this.keuzes.soortPaal == 'hout') {
                return true;
            } else {
                return false;   
            }
        }else{
          return false;
        }
    },  
    aantalPlanken() {
        if((this.keuzes.typeSchutting == '' && this.keuzes.soortPaal != 'hout')){
            return false;
        }else if((this.keuzes.typeSchutting == '' &&  this.keuzes.soortPaal == 'hout'  )) {
            return true;
        }
        else if((this.keuzes.soortHout != '' &&  this.keuzes.soortScherm == 'verticaal'  )) {
            return true;
        }else if((this.keuzes.soortHout != '' &&  this.keuzes.soortScherm == 'horizontaal' )) {
            return true;
        }else{
          return false;
        }
     
    },
    houtenAfdekkap() {
        if((this.keuzes.aantalPlanken != '' && this.keuzes.soortScherm == 'verticaal' || this.keuzes.aantalPlanken != '' && this.keuzes.soortScherm == 'horizontaal')){
            return true;
        }
      return false;
    },

    motiefKiezen() {
       if((this.keuzes.soortScherm == 'volledig_beton')){
            return true;
        }else if((this.keuzes.typeSchutting == 'luxe' || this.keuzes.typeSchutting == 'semi_luxe'  )) {
            return false;
        }
        else if((this.keuzes.soortPaal != 'hout' && this.keuzes.keuzenHoutenAfdekkap != '' && this.keuzes.soortScherm != 'dicht_blokhut')) {
            return true;
        }else{
          return false;
        }
    },
    kleurBeton() {
     if((this.keuzes.soortPaal == 'hout')) {
        return false;
    }else if((this.keuzes.keuzenHoutenAfdekkap != '' && this.keuzes.typeSchutting == 'luxe' || this.keuzes.keuzenHoutenAfdekkap != '' && this.keuzes.typeSchutting == 'semi_luxe' )){
        return true;
    }
    else if((this.keuzes.aantalPlanken == '' && this.keuzes.typeSchutting == 'luxe' || this.keuzes.typeSchutting == 'semi_luxe' )){
        return false;
    }
    else if((this.keuzes.soortMotief != '')){
        return true;
    }
    else if((this.keuzes.soortHout != '' && this.keuzes.soortScherm == 'dicht_blokhut' )){
        return true;
    }else {
      return false;
    }
  
    },
    betonAfdekkap() {
    if((this.keuzes.betonKleur != '' && this.keuzes.soortPaal != 'hout')){
        return true;
    }
    return false;
    },
    keuzenPoort() {
    if((this.keuzes.keuzenBetonenAfdekkap != '' || this.keuzes.keuzenHoutenAfdekkap != '' && this.keuzes.soortPaal == 'hout')){
        return true;
    }
    return false;
    },
    soortPoort() {
    if((this.keuzes.soortScherm == 'volledig_beton' )){
        return true;
    }
    return false;
    },

  },


  
}
</script>