import Vue from 'vue'
import App from './App.vue'
import VueScrollTo from 'vue-scrollto'

Vue.config.productionTip = false

// You can also pass in the default options
Vue.use(VueScrollTo, {
  container: "body",
  duration: 3000,
  easing: "smooth",
  offset: -50, 
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})



new Vue({
  render: h => h(App),
}).$mount('#app')
